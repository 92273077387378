.explore {
    width: 300px
}

.download {
    width: 300px;
}

.buttonContainer {
    margin: 1em 0;
}

.chromeImage {
    width: 40px;
}

.teaser {
    max-width: 100%;
}

.example:hover {
    cursor: pointer;
    background-color: #e2e2e2;
}