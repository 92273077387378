.ripple {
    background-position: center;
    background-color: white;
    transition: background 0.8s;
    padding: 0.5em;
    margin: 0.5em 0;
    border-radius: 8px;
  }
  .ripple:hover {
    cursor: pointer;
    background: #f6f5f7 radial-gradient(circle, transparent 1%, #f6f5f7 1%) center/15000%;
  }
  .ripple:active {
    background-color: #D3D3D3;
    background-size: 100%;
    transition: background 0s;
  }

  .active {
    background-position: center;
    background-color: #f6f5f7;
    transition: background 0.8s;
    padding: 0.5em;
    margin: 0.5em 0;
    border-radius: 8px;
    border: 1px #323639 solid;
  }
  .active:hover {
    cursor: pointer;
    background: #f6f5f7 radial-gradient(circle, transparent 1%, #f6f5f7 1%) center/15000%;
  }
  .active:active {
    background-color: #f6f5f7;
    background-size: 100%;
    transition: background 0s;
  }
  
  
