.module {
    margin: 0 0 1em 0;
    padding: 0.5em 1em;
    width: 95%;
}

.info:hover {
    cursor: pointer
}

.navIcon {
    background-color: red;
}
.logo {
    max-width: 28px;
}

.navExplore {
    margin-right: 10em;
}

.navExplore:hover {
    color: #7091E6;
}

.select {
    width: 12vw;
}