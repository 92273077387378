.response:hover {
    outline: 1px #d8d8d8 solid;
    
    cursor: pointer;
}

.selected {
    outline: 2px #7091E6 solid;
}

.selected:hover {
    cursor: pointer;
}