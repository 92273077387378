.header {
    border-radius: 8px;
    background: rgb(100,131,210);
    background: linear-gradient(180deg, rgba(100,131,210,1) 6%, rgba(173,187,218,1) 100%);
    padding: 2em;
    color: white
}

.pack {
    padding: 1em;
    outline: 1.4px solid #323639;
    border-radius: 8px;
}
@media (max-width: 800px) {
    .pack {
        padding: 2em;
        outline: 1.4px solid #323639;
        border-radius: 8px;
    }
  }
